jQuery(function($) {

  initMobileMenu();

  function initMobileMenu() {
    //Toggle's initial state must be toggle-closed
    $('.toggle-hidden-menu').on('click', function() {
        if(!$(this).hasClass('toggle-opened')) {
          $main_menu = $($('.fbyf-navbar')[0]);
          menu_height = $main_menu.offset().top + $main_menu.height();
          $(this).addClass('toggle-opened').removeClass('toggle-closed');
          $('.hidden-menu').css('top', menu_height);
          $('.burger').toggleClass('burger-x');
          $('body, html').addClass('body-is-mobile');
          $('.hidden-menu.closed').addClass('opened').removeClass('closed');
        }
        else {
          $(this).addClass('toggle-closed').removeClass('toggle-opened');
          $('body, html').removeClass('body-is-mobile');
          $('.burger').toggleClass('burger-x');
          $('.hidden-menu.opened').addClass('closed').removeClass('opened');
        }
    });

    $('.hidden-menu .menu-item-has-children .fl-menu-toggle').on('click', function (e) {
        $this = $(this);
        $submenu = $this.parent().siblings('.sub-menu');
        if(!$this.hasClass('toggle-opened')){
          $this.addClass('toggle-opened').removeClass('toggle-closed');
          scrollHeight = $submenu[0].scrollHeight;
          $submenu.css( "max-height", scrollHeight );

        } else {
          $this.addClass('toggle-closed').removeClass('toggle-opened');
          $submenu.css( "max-height", "0" );
          console.log('submenu closed');
        }
    });
  }

});

jQuery(function($) {

    $('.dropdown-toggle').click(function() {
        $('.categories').toggleClass('show');
    });

});

jQuery(function($) {

	initSiteSearch();

	function initSiteSearch() {
		$('.open-search').on('click', function() {
			var $content = $('.hidden-modal--search .search-form');

			setTimeout(function () {
				$.magnificPopup.open({
					removalDelay: 0,
					closeBtnInside: true,
					showCloseBtn: true,
					overflowY: 'scroll',
					mainClass: 'modal__wrapper',
					items: {
					src: $content
					},
					type: 'inline'
				});
			}, 100);

			return false;
		});
	}

});
